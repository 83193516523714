import React from 'react'
import Tooltip from '@mui/material/Tooltip'
import { FormLabel } from '@mui/material'
import { styled } from '@mui/material/styles'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import Fonts from '#/styles/Fonts'
import Colors from '#/styles/Colors'

const Help = styled(HelpOutlineIcon)({
  'marginLeft': 5,
  'fontSize': Fonts.fontSize.M,
  'color': Colors.warning,
  '.MuiInputLabel-shrink &': {
    transform: 'translate(0, 1.5px) scale(calc(1 + 1 / 3))',
  },
})

const Content = styled('div')({
  display: 'inline-flex',
  position: 'relative',
  alignItems: 'flex-start',
  cursor: 'help',
})

const HelpContent = styled('div')({
  padding: '8px 4px 4px',
  width: 200,
  maxWidth: '100%',
})

const LabelWithHelp = ({ label, help }) => {
  if (help?.body && help?.title) {
    return (
      <FormLabel required={false}>
        <Tooltip
          arrow
          title={
            <HelpContent>
              <div style={{ color: Colors.warning, marginBottom: 10 }}>
                {help.title}
              </div>
              <div>{help.body}</div>
            </HelpContent>
          }
        >
          <Content>
            <div>{label}</div>
            <Help />
          </Content>
        </Tooltip>
      </FormLabel>
    )
  }

  return label
}

export { LabelWithHelp }
