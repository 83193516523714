import omit from 'lodash/omit'
import { isEmpty, max, min } from 'lodash'
import InputText from '#/components/FormControl/Inputs/InputText'
import InputPhone from '#/components/FormControl/Inputs/InputPhone'
import InputRadio from '#/components/FormControl/Inputs/InputRadio'
import InputSwitch from '#/components/FormControl/Inputs/InputSwitch'
import InputMask from '#/components/FormControl/Inputs/InputMask'
import EmailInput from '#/components/FormControl/Inputs/EmailInput'
import PinMapInput from '#/components/FormControl/Inputs/PinMap'
import DateTimePicker from '#/components/FormControl/Inputs/DateTimePicker'
import TextEditor from '#/components/FormControl/Inputs/TextEditor'
import TimePickerInput from '#/components/FormControl/Inputs/TimePicker'
import InputFile from '#/components/FormControl/Inputs/InputFile'
import InputImage from '#/components/FormControl/Inputs/InputImage'
import { FormBuilderSession } from '#/components/FormControl/Fields/session'
import { FormBuilderMessage } from '#/components/FormControl/Fields/message'
import { FormBuilderNumber } from '#/components/FormControl/Fields/number'
import { FormBuilderCollapse } from '#/components/FormControl/Fields/collapse'
import { FormBuilderGroup } from '#/components/FormControl/Fields/group'
import { FormBuilderSelectASync } from '#/components/FormControl/Fields/Select/async'
import { FormBuilderSelectSync } from '#/components/FormControl/Fields/Select/sync'
import { FormBuilderSelectSearchable } from '#/components/FormControl/Fields/Select/searchable'
import { FormBuilderColor } from '#/components/FormControl/Fields/Color'
import Moment from '#/components/Moment'

function getMinMaxDate(props) {
  const maxDate = min([
    props?.rules?.before_or_equal?.value,
    props?.rules?.before?.value,
  ])

  const minDate = max([
    props?.rules?.after_or_equal?.value,
    props?.rules?.after?.value,
  ])

  if (props.type === 'date') {
    return {
      maxDate: maxDate
        ? Moment(maxDate).subtract(1, 'day').format('YYYY-MM-DD')
        : null,
      minDate: minDate
        ? Moment(minDate).add(1, 'day').format('YYYY-MM-DD')
        : null,
    }
  }

  return {
    maxDate: min([
      props?.rules?.before_or_equal?.value,
      props?.rules?.before?.value,
    ]),
    minDate: max([
      props?.rules?.after_or_equal?.value,
      props?.rules?.after?.value,
    ]),
  }
}

const FormBuilderWrapperSelect = props => {
  function handleChange(options) {
    if (isEmpty(options)) {
      return props.onChange(options)
    }

    if (props.multi) {
      return props.onChange(options.map(options => options.value))
    }

    return props.onChange(options.value)
  }
  if (props.searchable) {
    return <FormBuilderSelectSearchable {...props} onChange={handleChange} />
  }
  if (props.source) {
    return <FormBuilderSelectASync {...props} onChange={handleChange} />
  }
  return <FormBuilderSelectSync {...props} onChange={handleChange} />
}

/* eslint-disable no-unused-vars */
/* prettier-ignore */
const FormBuilderFields = {
  'collapse':     props =>  <FormBuilderCollapse        {...props} />,
  'group':        props =>  <FormBuilderGroup           {...props} />,
  'select':       props =>  <FormBuilderWrapperSelect   {...props} />,
  'number':       props =>  <FormBuilderNumber          {...omit(props, ['setValues'])} />,
  'percentage':   props =>  <FormBuilderNumber          {...omit(props, ['setValues'])} />,
  'integer':      props =>  <FormBuilderNumber          {...omit(props, ['setValues'])} />,
  'session':      props =>  <FormBuilderSession         {...omit(props, ['setValues'])} />,
  'message':      props =>  <FormBuilderMessage         {...omit(props, ['setValues'])} />,
  'color':        props =>  <FormBuilderColor           {...omit(props, ['setValues'])} />,
  'date':         props =>  <DateTimePicker             {...omit(props, ['setValues'])} {...getMinMaxDate(props)}/>,
  'time':         props =>  <TimePickerInput            {...omit(props, ['setValues'])} />,
  'tel':          props =>  <InputPhone                 {...omit(props, ['setValues'])} />,
  'file':         props =>  <InputFile                  {...omit(props, ['setValues'])} />,
  'image':        props =>  <InputImage                 {...omit(props, ['setValues'])} />,
  'text':         props =>  <InputText                  {...omit(props, ['setValues'])} />,
  'password':     props =>  <InputText                  {...omit(props, ['setValues'])} />,
  'email':        props =>  <InputText                  {...omit(props, ['setValues'])} />,
  'choice':       props =>  <InputRadio                 {...omit(props, ['setValues'])} />,
  'boolean':      props =>  <InputSwitch                {...omit(props, ['setValues'])} />,
  'mask':         props =>  <InputMask                  {...omit(props, ['setValues'])} />,
  'textarea':     props =>  <InputText                  {...omit(props, ['setValues'])} multiline rows={3} />,
  'wysiwyg':      props =>  <TextEditor                 {...omit(props, ['setValues'])} />,
  'pin':          props =>  <PinMapInput                {...omit(props, ['setValues'])} />,
  'email-list':   props =>  <EmailInput                 {...omit(props, ['setValues'])} />,
  'timestamp':    props =>  <DateTimePicker             {...omit(props, ['setValues'])} {...getMinMaxDate(props)} />,
  'separator':    props =>  <br />,
}

export { FormBuilderFields }
