import { ThemeProvider } from '@mui/material/styles'
import React, { Suspense } from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import CircularProgress from '@mui/material/CircularProgress'
import { Toaster } from 'sonner'
import { isDev } from '#/utils'
import Flex from '#/components/Flex'
import ErrorBoundary from '#/components/ErrorBoundary'
import EnvTag from '#/components/EnvTag'
import Routes from './routes'
import { HelpPageCheck } from './components/HelpPages'
import Consent from '#/components/Consent'
import defaultTheme from '#/styles/Themes'
import PageLoader from '#/components/PageLoader'

export default function App() {
  const theme = React.useMemo(() => defaultTheme(), [])

  return (
    <ThemeProvider theme={theme}>
      <ErrorBoundary>
        <PageLoader />
        <Router>
          <Suspense
            fallback={
              <Flex>
                <CircularProgress />
              </Flex>
            }
          >
            <Routes />
          </Suspense>
        </Router>
        {isDev && <EnvTag />}
        <HelpPageCheck />
        <Consent />
        <Toaster richColors position="bottom-center" />
      </ErrorBoundary>
    </ThemeProvider>
  )
}
