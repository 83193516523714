import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router'
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Typography,
} from '@mui/material'
import { styled } from '@mui/material/styles'
import { fetcher } from '@chipinside/fetcher'
import Translate from '#/components/Translate'
import tryCache from '#/utils/tryCache'
import { resetFirebaseToken } from '#/store/ducks/auth'

import Fonts from '#/styles/Fonts'
import Palette from '#/styles/Palette'

const Wrapper = styled(Card)({
  background: Palette.gray.self[700],
  color: Palette.green.self[500],
  fontSize: Fonts.fontSize.L,
  zIndex: 5,
  position: 'absolute',
  bottom: 20,
  right: 20,
  maxWidth: '40ch',
  opacity: 0.9,
})

export default function RevalidateVersion() {
  const [newVersion, setNewVersion] = useState(false)

  const history = useHistory()
  const dispatch = useDispatch()

  const buildVersion = import.meta.env.VITE_VERSION

  useEffect(() => {
    const interval = setInterval(() => {
      const controller = `${window.location.origin}/version.txt?${Date.now().toString()}`
      fetcher({ controller })
        .then(({ data: version }) => {
          if (buildVersion !== version) {
            setNewVersion(true)
          }
        })
        .catch(error => console.error(error))
    }, 60 * 1000)

    return () => clearInterval(interval)
  }, [])

  const handleValidateVersion = () => {
    localStorage.removeItem('persist:root')
    tryCache.clearAll()
    /* Resetting the firebase token. */
    dispatch(resetFirebaseToken())
    history.go()
  }

  return (
    newVersion && (
      <Wrapper variant="outlined">
        <CardContent>
          <Typography variant="body2" gutterBottom>
            {Translate({ messageKey: 'outdated_version_message' })}
          </Typography>
        </CardContent>
        <CardActions>
          <Button
            size="small"
            variant="contained"
            onClick={handleValidateVersion}
          >
            {Translate({ messageKey: 'reload_now' })}
          </Button>
        </CardActions>
      </Wrapper>
    )
  )
}
