import omit from 'lodash/omit'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { fetcher } from '@chipinside/fetcher'
import Translate from '#/components/Translate'
import { toggleDialog } from '#/store/ducks/dialog'

export const saveEvent = createAsyncThunk(
  'event/saveEvent',
  async (
    {
      method = 'post',
      controller,
      name,
      params,
      actionsOnSuccess,
      retryOnConflict,
      renderCustomResponse = null,
    },
    thunkAPI,
  ) => {
    if (!controller) {
      return thunkAPI.rejectWithValue({
        name,
        error: 'insert a controller value',
      })
    }

    try {
      const { data = {}, code } = await fetcher({
        controller,
        method,
        params,
      })

      thunkAPI.dispatch(
        toggleDialog({
          name,
          open: true,
          code,
          message: data.message,
          actionsOnSuccess:
            typeof actionsOnSuccess === 'function'
              ? actionsOnSuccess(data)
              : actionsOnSuccess,
          renderCustomResponse: renderCustomResponse?.(data),
        }),
      )

      return { name, controller, params, data, code }
    } catch (error) {
      if (error?.data?.error) {
        const {
          data,
          status_code: code,
          message,
          details,
          extra,
        } = error.data.error

        let conflictsAction = {}

        // conflicts force
        if (extra) {
          conflictsAction = {
            label: Translate({ messageKey: 'confirm_item' }),
            handleAction: { name, extra },
          }
          if (retryOnConflict) {
            conflictsAction.handleAction.retryParams = {
              method,
              controller,
              name,
              params: { ...params, [extra]: true },
              retryOnConflict,
              actionsOnSuccess:
                typeof actionsOnSuccess === 'function'
                  ? actionsOnSuccess(data)
                  : actionsOnSuccess,
            }
          }
        }

        thunkAPI.dispatch(
          toggleDialog({
            name,
            open: true,
            message,
            details,
            code,
            extra,
            conflictsAction,
            renderCustomResponse: renderCustomResponse?.(error),
          }),
        )

        return thunkAPI.rejectWithValue({ name, error: message, code })
      }

      return thunkAPI.rejectWithValue({ error: 'unknown error' })
    }
  },
)

export const eventSlice = createSlice({
  name: 'event',
  initialState: {},
  reducers: {
    resetSaveEvent(state, action) {
      return omit(state, action.payload?.name)
    },
  },
  extraReducers: builder => {
    builder
      .addCase(saveEvent.pending, (state, action) => {
        state[action.meta.arg.name] = {
          ...(state?.[action.meta.arg.name] ?? {}),
          controller: action.meta.arg.controller,
          status: 'started',
          error: null,
        }
      })
      .addCase(saveEvent.fulfilled, (state, action) => {
        state[action.meta.arg.name] = action.payload
        state[action.meta.arg.name].status = 'done'
      })
      .addCase(saveEvent.rejected, (state, action) => {
        state[action.meta.arg.name].error = action.payload?.error
        state[action.meta.arg.name].status = 'failed'
      })
  },
  selectors: {
    getEvent: (state, name) => state?.[name] ?? {},
  },
})

// actions
export const {
  actions: { resetSaveEvent },
  selectors: { getEvent },
} = eventSlice

export default eventSlice.reducer
