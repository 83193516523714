import React from 'react'
import CowmedChip from '#/components/Chip'
import Colors from '#/styles/Colors'

/**
 * Chip de Status de Lactação.
 * @param {string} props.status - O status do chip (lactating, dry, early, fresh).
 * @param {string} props.text - O texto do chip.
 * @returns {JSX.Element} - Elemento JSX que renderiza o componente.
 */
const TypeProduction = ({ value }) => {
  if (!value?.status) return '-'

  let statusStyle
  // Definindo o estilo com base no status
  switch (value.status) {
    case 'lactating':
      statusStyle = Colors.production.lactating
      break

    case 'dry':
      statusStyle = Colors.production.dry
      break

    default:
      break
  }

  return (
    <CowmedChip value={value.text} customStyle={statusStyle} width="67px" />
  )
}

export default TypeProduction
