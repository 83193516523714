import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { isEmpty } from 'lodash'
import { endpoint } from '#/store/services/endpoints'
import tryCache from '#/utils/tryCache'
import { getPermissions } from '#/store/ducks/auth'
import { fetcher } from '@chipinside/fetcher'

const defaultState = {
  currentCompany: {},
}
// equivalent to loadCompanyStatus
export const loadCurrentCompanyInfo = createAsyncThunk(
  'company/loadCurrentCompanyInfo',
  async (payload, thunkAPI) => {
    const { slug } = payload
    const state = thunkAPI.getState()

    // checks if same company infos expired
    if (
      slug === state?.company?.currentCompany?.slug &&
      slug === state?.company?.currentCompany?.data?.slug &&
      !tryCache.isExpired(state?.company?.currentCompany?.expires)
    ) {
      return {
        expires: state.company.currentCompany.expires,
        data: state.company.currentCompany.data,
      }
    }

    if (!isEmpty(slug)) {
      try {
        const controller = endpoint.company.self({ company: slug })
        const { data } = await fetcher({ controller })

        // get company permissions
        await thunkAPI.dispatch(
          getPermissions({ context: 'company', companySlug: slug }),
        )

        return {
          expires: tryCache.expiresIn(10), // 10 minutes
          data,
        }
      } catch (e) {
        return thunkAPI.rejectWithValue({
          slug,
          message: e.message,
        })
      }
    }
    return thunkAPI.rejectWithValue({
      slug,
      message: 'load company info not handled',
    })
  },
)

export const companySlice = createSlice({
  name: 'company',
  initialState: defaultState,
  reducers: {
    setCurrentCompany(state, action) {
      state.currentCompany = action?.payload
    },
    resetCurrentCompany(state) {
      state.currentCompany = {}
    },
  },
  extraReducers: builder => {
    builder
      .addCase(loadCurrentCompanyInfo.pending, (state, action) => {
        state.currentCompany = {
          ...(state.currentCompany ?? defaultState.currentCompany),
          slug: action.meta.arg.slug,
          status: 'started',
          error: '',
        }
      })
      .addCase(loadCurrentCompanyInfo.fulfilled, (state, action) => {
        state.currentCompany = {
          data: action.payload?.data,
          slug: action.meta.arg.slug,
          status: 'done',
          error: '',
          expires: action.payload?.expires,
        }
      })
      .addCase(loadCurrentCompanyInfo.rejected, (state, action) => {
        state.currentCompany = {
          ...(state.currentCompany ?? defaultState.currentCompany),
          slug: action.meta.arg.slug,
          status: 'failed',
          error: action.payload?.message,
        }
      })
  },
})

export const { setCurrentCompany, resetCurrentCompany } = companySlice.actions

// State Selectors
export const getCurrentCompany = state =>
  state?.company?.currentCompany?.data ?? {}
export const getCurrentCompanyInfo = state =>
  state?.company?.currentCompany ?? {}
export const getCurrentCompanySlug = state =>
  state?.company?.currentCompany?.data?.slug ?? null
export const getCurrentCompanyName = state =>
  state?.company?.currentCompany?.data?.name ?? null

export default companySlice.reducer
