import * as React from 'react'
import { debounce } from '@mui/material/utils'
import { isEmpty } from 'lodash'
import { createFilterOptions } from '@mui/material/Autocomplete'
import { fetcher } from '@chipinside/fetcher'
import Translate from '#/components/Translate'
import {
  FormBuilderSelectAddGroupToOptions,
  Select,
} from '#/components/FormControl/Fields/Select'

const filter = createFilterOptions()
function clearOnBackspace(event) {
  if (event.key === 'Backspace') {
    const clearButton = event.target.parentElement?.querySelector(
      '.MuiAutocomplete-clearIndicator',
    )
    clearButton?.click()
  }
}

export function FormBuilderSelectSearchable({
  source = {},
  multi,
  onChange,
  allowCreateOption,
  ...props
}) {
  const [value, setValue] = React.useState(props.value ?? null)
  const [inputValue, setInputValue] = React.useState('')
  const [loading, setLoading] = React.useState(false)
  const [options, setOptions] = React.useState([])
  const [noOptionsText, setNoOptionsText] = React.useState(
    Translate({ messageKey: 'type_to_find' }),
  )

  const fetch = React.useMemo(
    () =>
      debounce((search, value, callback) => {
        let ignore

        if (!isEmpty(value)) {
          ignore = Array.isArray(value)
            ? value?.map(({ value }) => value).join(',')
            : value.value
        }

        fetcher({
          controller: source.uri,
          params: { ...source.params, search, ignore },
        }).then(callback)
      }, 1000),
    [],
  )

  React.useEffect(() => {
    if (inputValue) {
      setLoading(true)
      fetch(inputValue, value, results => {
        if (results) {
          setLoading(false)
          setOptions(FormBuilderSelectAddGroupToOptions(results?.data?.results))
          if (!results?.data.total) {
            setNoOptionsText(Translate({ messageKey: 'no_results_found' }))
          }
        }
      })
    }
  }, [inputValue, fetch])

  const handleChange = option => {
    setValue(option)
    onChange(option)
  }

  return (
    <Select
      {...props}
      value={value}
      onClose={() => {
        setOptions([])
        setInputValue(null)
        setNoOptionsText(Translate({ messageKey: 'type_to_find' }))
      }}
      options={options}
      multiple={multi}
      onChange={handleChange}
      onInputChange={(_, newInputValue, reason) => {
        if (reason === 'input') {
          setNoOptionsText(Translate({ messageKey: ['loading', '...'] }))
          setInputValue(newInputValue)
        }
      }}
      textfieldprops={!multi ? { onKeyDown: clearOnBackspace } : {}}
      noOptionsText={noOptionsText}
      filterOptions={(options, params) => {
        const { inputValue } = params
        const filtered = filter(options, params)

        const isNewOption =
          inputValue && !options.some(option => option.value === inputValue)

        if (!loading && allowCreateOption && isNewOption) {
          filtered.push({
            value: inputValue,
            label: inputValue,
            isNewOption: true,
          })
        }

        return filtered
      }}
      renderOption={(props, option) => {
        const { key, ...optionProps } = props
        const label =
          allowCreateOption && option.isNewOption
            ? Translate({
                messageKey: 'add_item',
                params: { item: `"${option.label}"` },
              })
            : option.label

        return (
          <li key={key} {...optionProps}>
            {label}
          </li>
        )
      }}
    />
  )
}
