import * as React from 'react'
import TextField from '@mui/material/TextField'
import { CircularProgress } from '@mui/material'
import { isEmpty, isNil, isPlainObject, pick } from 'lodash'
import SearchIcon from '@mui/icons-material/Search'
import Autocomplete from '@mui/material/Autocomplete'
import Translate from '#/components/Translate'

// Lista de propriedades permitidas para o componente Select
const SelectWhiteListProps = [
  'error',
  'disabled',
  'onClose',
  'label',
  'loading',
  'name',
  'options',
  'onBlur',
  'onChange',
  'onInputChange',
  'placeholder',
  'required',
  'filterOptions',
  'renderOption',
  'value',
]

export function FormBuilderSelectGetValue(options, value, multiple) {
  let result

  if (multiple) {
    result =
      value?.map(val => {
        return typeof val === 'string'
          ? options?.find(option => option.value === val)
          : val
      }) || []
  } else {
    result =
      !isNil(value) && !isPlainObject(value)
        ? options?.find(option => option.value === value)
        : value
  }

  if (!result) {
    result = multiple ? [] : null
  }

  return result
}

// Função para adicionar informações de grupo às opções
export function FormBuilderSelectAddGroupToOptions(options) {
  if (options?.some(option => option.options)) {
    const formattedOptions = options.flatMap(({ options, ...parent }) =>
      // Adiciona a label do grupo às opções
      options.map(option => ({ ...option, group: parent.label })),
    )
    return formattedOptions
  }
  return options
}

// Função para renderizar o campo de entrada (TextField)
function renderInput(
  params,
  value,
  {
    error,
    label,
    required,
    textfieldprops,
    placeholder = Translate({
      messageKey: 'select_one_item_female',
      params: {
        item: Translate({ messageKey: 'option', lower: true }),
      },
    }),
  },
) {
  return (
    <TextField
      {...params}
      {...textfieldprops}
      error={Boolean(error)}
      helperText={error}
      required={required}
      label={label}
      placeholder={isEmpty(value) ? placeholder : null}
      slotProps={{
        inputLabel: { shrink: true },
        input: params.InputProps,
      }}
    />
  )
}

const popupIcon = others => {
  if (others.searchable) {
    return <SearchIcon />
  }
  if (others.loading) {
    return <CircularProgress />
  }
  return undefined
}

function Select(props) {
  const {
    onChange,
    multiple,
    limitTags = 5,
    noOptionsText = Translate({ messageKey: 'without_information' }),
    ...others
  } = props

  const handleChange = (_, options) => {
    onChange?.(options)
  }

  return (
    <Autocomplete
      className={others.searchable ? 'searchable' : undefined}
      popupIcon={popupIcon(others)}
      // Filtra propriedades permitidas
      {...pick(props, SelectWhiteListProps)}
      autoHighlight
      selectOnFocus={false}
      multiple={multiple}
      onChange={handleChange}
      filterSelectedOptions={multiple}
      // Desabilita opções se o máximo permitido foi atingido
      getOptionDisabled={() => multiple && props.value?.length >= others.max}
      // Agrupa opções por grupo
      groupBy={option => option?.group}
      // Limita tags exibidas no campo
      limitTags={limitTags}
      // Exibe o rótulo das opções
      getOptionLabel={option => String(option?.label ?? '')}
      // Renderiza o campo de entrada
      renderInput={params => renderInput(params, props.value, others)}
      loadingText={Translate({ messageKey: ['loading', '...'] })}
      noOptionsText={noOptionsText}
    />
  )
}

export { Select }
