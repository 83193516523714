import React, { useEffect } from 'react'
import { useFetch } from '@chipinside/fetcher'
import {
  FormBuilderSelectGetValue,
  Select,
} from '#/components/FormControl/Fields/Select'

export function FormBuilderSelectASync(props) {
  const { source = {} } = props
  const [options, setOptions] = React.useState([])
  const [value, setValue] = React.useState([])

  const { isLoading, data } = useFetch({
    controller: source.uri,
    params: source.params,
  })

  useEffect(() => {
    if (!isLoading && data?.results) {
      setOptions(data.results)
    }
  }, [data])

  useEffect(() => {
    setValue(FormBuilderSelectGetValue(options, props.value, props.multi))
  }, [options, props.value])

  return (
    <Select
      {...props}
      value={value}
      loading={isLoading}
      options={options}
      multiple={props.multi}
    />
  )
}
