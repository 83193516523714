import React, { useMemo, useRef, useState } from 'react'
import { isEmpty } from 'lodash'

import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import { makeStyles } from '@mui/styles'
import { styled } from '@mui/material/styles'
import { useFetch } from '@chipinside/fetcher'
import { LoadingIconButton } from '@chipinside/frontend'
import { endpoint } from '#/store/services/endpoints'
import Translate from '#/components/Translate'
import SimpleDialog from '#/components/Modal'
import Moment from '#/components/Moment'
import ConnectedIcon from '#/icons/Connected'
import Colors from '#/styles/Colors'
import ListLoader from '#/components/Loader/ContentLoader/ListLoader'

const useStyles = makeStyles(() => ({
  root: {
    position: 'relative',
  },
}))
const StyledDivider = styled(Divider)({
  marginTop: 15,
})
const TextStatus = styled('span')(({ color }) => ({
  fontWeight: 700,
  color,
}))

const FarmStatus = props => {
  const { status, slug, farm } = props
  const classes = useStyles()
  const [openStatus, setOpenStatus] = useState(false)
  const statusRef = useRef(status)

  const controller = useMemo(
    () =>
      slug && !statusRef.current ? endpoint.farm.status({ farm: slug }) : null,
    [slug, statusRef.current],
  )

  const { data, isLoading, mutate } = useFetch({
    controller,
    swrOptions: {
      refreshInterval: 5 * 60 * 1000,
    },
  })

  let last_sync_update = []

  if (!isLoading && !isEmpty(data) && Array.isArray(data)) {
    last_sync_update = data.map(unit => unit.last_sync_update)
    last_sync_update.sort()
  }

  const getColorStatus = date => {
    // Se não há data e status é 'NA', retorna a cor neutra
    if (!date && status === 'NA') {
      return Colors.feedback.neutral.color
    }

    // Calcula a diferença em horas entre a data fornecida e o momento atual
    const diffInHours = Moment.diff('now', date, 'h')

    // less than 4 hours
    if ((date && diffInHours < 4) || status === 'OK') {
      return Colors.feedback.success.color
    }
    // between 4 and 12 hours
    if (
      (date && diffInHours >= 4 && diffInHours < 12) ||
      status === 'WARNING'
    ) {
      return Colors.feedback.warning.color
    }
    // 12 hours or more
    return Colors.feedback.danger.color
  }

  const renderDateColorStatus = date => {
    if (!date) return '-'

    return (
      <TextStatus color={getColorStatus(date)}>{Moment.view(date)}</TextStatus>
    )
  }

  const renderModalContent = () => {
    if (isLoading) {
      return <ListLoader lines={2} />
    }

    if (!isLoading && !isEmpty(data)) {
      return data
        .sort((a, b) => b.active - a.active)
        .map((unit, index, data) => {
          return (
            <React.Fragment key={`monitoring-status-${unit.slug}`}>
              <p style={{ fontWeight: 700 }}>{unit.name}</p>
              {unit.active ? (
                <>
                  <Typography>
                    {Translate({ messageKey: 'last_cloud_sync' })}:{' '}
                    {renderDateColorStatus(unit.last_sync_update)}
                  </Typography>
                  <Typography>
                    {Translate({ messageKey: 'last_status' })}:{' '}
                    {renderDateColorStatus(unit.last_status_update)}
                  </Typography>
                  <Typography>
                    {Translate({ messageKey: 'last_report' })}:{' '}
                    {renderDateColorStatus(unit.last_monitoring_update)}
                  </Typography>
                </>
              ) : (
                <Typography>
                  {Translate({
                    messageKey: 'unit',
                    params: {
                      item: Translate({
                        messageKey: 'inactive_female',
                        lower: true,
                      }),
                    },
                  })}
                </Typography>
              )}
              {data.length - 1 !== index ? <StyledDivider /> : null}
            </React.Fragment>
          )
        })
    }

    return Translate({ messageKey: 'without_information' })
  }

  return (
    <div className={classes.root}>
      <LoadingIconButton
        loading={isLoading}
        icon={<ConnectedIcon color={getColorStatus(last_sync_update[0])} />}
        onClick={() => {
          statusRef.current = null
          mutate()
          setOpenStatus(true)
        }}
        size="large"
      />
      <SimpleDialog
        fullWidth
        maxWidth="sm"
        open={openStatus}
        onClose={() => setOpenStatus(false)}
        title={Translate({
          messageKey: ['c_com_communication_status', farm],
          translate: [true, false],
          separator: [' - '],
        })}
        element={renderModalContent()}
      />
    </div>
  )
}

export default FarmStatus
