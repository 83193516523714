import ReactDOM from 'react-dom/client'
import { createFetcherInstance } from '@chipinside/fetcher'
import App from './App'
import tryCache from '#/utils/tryCache'
import initializeSentry from '#/utils/initSentry'
import initializeFirebase from '#/store/services/Firebase'
import Providers from '#/store/providers/Providers'
import { axiosInstance } from '#/store/services/AxiosInstance'

import './index.css'

/* Initialize fetcher instance */
createFetcherInstance({
  instance: axiosInstance,
})

/* A function that removes expired items from the browser's local storage. */
tryCache.clearExpired()

/* Initializing the Firebase SDK. */
initializeFirebase()

/* Initializing the Sentry SDK. */
initializeSentry()

ReactDOM.createRoot(document.getElementById('root')).render(
  <Providers>
    <App />
  </Providers>,
)
