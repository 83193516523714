import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import isEmpty from 'lodash/isEmpty'
import omit from 'lodash/omit'
import { fetcher } from '@chipinside/fetcher'

export const loadFormFields = createAsyncThunk(
  'form/loadFormFields',
  async (payload, thunkAPI) => {
    const { controller, name, params, headers = {} } = payload

    if (!isEmpty(controller)) {
      try {
        const { data } = await fetcher({ controller, params, headers })

        return {
          name,
          controller,
          fields: data,
          params,
        }
      } catch (e) {
        return thunkAPI.rejectWithValue({
          name,
          controller,
          message: e.message,
        })
      }
    }

    return thunkAPI.rejectWithValue({
      name,
      controller,
      message: 'form fields not found',
    })
  },
)

const formSlice = createSlice({
  name: 'form',
  initialState: {},
  reducers: {
    resetFormFields(state, action) {
      return omit(state, action.payload?.name)
    },
  },
  extraReducers: builder => {
    builder
      .addCase(loadFormFields.pending, (state, action) => {
        state[action.meta.arg.name] = {
          controller: action.meta.arg.controller,
          status: 'started',
          error: '',
          data: {},
        }
      })
      .addCase(loadFormFields.fulfilled, (state, action) => {
        state[action.payload?.name] = {
          controller: action.payload?.controller,
          data: action.payload?.fields,
          status: 'done',
          error: '',
        }
      })
      .addCase(loadFormFields.rejected, (state, action) => {
        if (action.payload?.name) {
          state[action.payload?.name] = {
            data: state?.[action.payload?.name]?.data ?? {},
            status: 'failed',
            error: action.payload?.message,
          }
        }
      })
  },
})

export const { resetFormFields } = formSlice.actions

export const getFormFields = (state, formName) => state?.form[formName] ?? {}

export default formSlice.reducer
